import { useState, useEffect } from "react";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Fade from "@mui/material/Fade";
import Grow from "@mui/material/Grow";
// Mui Icons
import GitHubIcon from "@mui/icons-material/GitHub";
import AppDemoModal from "./AppDemoModal";
// Assets
import wailsGif from "../../assets/wails.gif"

//==============================================================================

export default function MeaningfulBookmarks() {
  const [showElements, setShowElements] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function handleVisitToRepo() {
    window.open(
      "https://github.com/J-Tapio/meaningful-bookmarks",
      "_blank",
      "noopener"
    );
  }

  useEffect(() => {
    setShowElements(true);
  }, []);

  return (
    <>
      <Grid item xs={12} md={6}>
        <Stack spacing={2}>
          <Fade in={showElements} timeout={2000}>
            <Typography id="meaningful-bookmarks" variant="h4">
              <Link
                href="https://github.com/J-Tapio/meaningful-bookmarks"
                rel="noreferrer"
                target="_blank"
                underline="none"
                sx={{
                  color: "inherit",
                  transition: "color .7s",
                  "&:hover": {
                    color: "#439d34",
                  },
                }}
              >
                Meaningful Bookmarks
              </Link>
            </Typography>
          </Fade>
          <Fade in={showElements} timeout={2500}>
            <Typography component="p" sx={{ lineHeight: "1.6rem" }}>
              I can't remember how many times I've had the frustration of
              browsing all the bookmarks in the browser when I had to find
              something again.
              <br />
              I wanted to make an app for myself where I could bookmark a
              webpage, save an image of the page with a note I wrote in
              markdown, and view the notes whenever I wanted.
              <br />
              <br />I somehow noticed that one can build cross-platform
              applications with{" "}
              <Link
                href="https://wails.io"
                rel="noreferrer"
                target="_blank"
                underline="none"
                sx={{
                  fontWeight: 500,
                  color: "inherit",
                  transition: "color .7s",
                  "&:hover": {
                    color: "#439d34",
                  },
                }}
              >
                Wails
              </Link>{" "}
              and decided learn how to use it since I already had tried{" "}
              <Link
                href="https://fyne.io"
                rel="noreferrer"
                target="_blank"
                underline="none"
                sx={{
                  fontWeight: 500,
                  color: "inherit",
                  transition: "color .7s",
                  "&:hover": {
                    color: "#439d34",
                  },
                }}
              >
                Fyne toolkit.
              </Link>{" "}
              <br />
              <br />
              Frontend is built with <b>React.js</b> and for styling{" "}
              <b>TailwindCSS</b> is used. Bookmarks are saved to <b>MongoDB</b>{" "}
              database.
              <br />
              <br />I think <b>Wails</b> is delightful to use since for frontend
              one can use eg. <b>React.js</b>, <b>Angular</b>, <b>Vue</b>.{" "}
              <br />
              Options for frontend seems limitless.
            </Typography>
          </Fade>
          <Fade in={showElements} timeout={3000}>
            <Typography variant="h6">Link to repository</Typography>
          </Fade>
          <Fade in={showElements} timeout={3000}>
            <GitHubIcon
              onClick={handleVisitToRepo}
              sx={{
                fontSize: "2rem",
                cursor: "pointer",
                "&:hover": { color: "#439d34" },
              }}
            />
          </Fade>
        </Stack>
      </Grid>
      <Grid item xs={12} md={6}>
        <AppDemoModal
          open={open}
          handleClose={handleClose}
          imageSrc={wailsGif}
        />
        <Grow in={showElements} timeout={3000}>
          <Box
            component="img"
            onClick={handleOpen}
            sx={{
              borderRadius: "10px",
              objectFit: "cover",
              width: "100%",
              cursor: { md: "pointer" },
            }}
            src={wailsGif}
          />
        </Grow>
      </Grid>
    </>
  );
}
