import { useEffect, useState } from "react";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Fade from "@mui/material/Fade";
// Mui Icons
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

//==============================================================================

export default function Footer() {
  const [fadeIn, setFadeIn] = useState(false);

  const takeToGithub = () => {
    window.open("https://www.github.com/J-Tapio", "_blank",
      "noopener")
  }

  const takeToLinkedIn = () => {
    window.open(
      "https://de.linkedin.com/in/juha-tapio-turpeinen-a89974137",
      "_blank",
      "noopener"
    );
  }

  useEffect(() => {
    setFadeIn(true);
  }, []);

  return (
    <Container sx={{ maxWidth: "1300px", marginY: "3rem" }}>
      <Fade in={fadeIn} timeout={2000}>
        <Typography variant="h6" textAlign={"center"}>
          <Link
            href={"https://juha-tap.io"}
            underline="none"
            rel="noreferrer"
            target="_blank"
            sx={{
              color: "inherit",
              transition: "color .7s",
              "&:hover": {
                color: "#439d34",
              },
            }}
          >
            &copy; Juha-Tapio Turpeinen - 2023
          </Link>
        </Typography>
      </Fade>
      <Typography
        component="p"
        sx={{
          textAlign: "center",
          marginTop: ".5rem",
          fontFamily: "monospace",
        }}
      >
        message (at) juha-tap.io
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1rem",
        }}
      >
        <GitHubIcon
          onClick={takeToGithub}
          sx={{
            cursor: "pointer",
            marginX: ".5rem",
            fontSize: "2rem",
            transition: "color .7s",
            "&:hover": {
              color: "#439d34",
            },
          }}
        />
        <LinkedInIcon
          onClick={takeToLinkedIn}
          sx={{
            cursor: "pointer",
            marginX: ".5rem",
            fontSize: "2.2rem",
            transition: "color .7s",
            "&:hover": {
              color: "#439d34",
            },
          }}
        />
      </Box>
    </Container>
  );
}
