import { useState, useEffect } from "react";
// MaterialUI
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Fade from "@mui/material/Fade";
import Grow from "@mui/material/Grow";
import Link from "@mui/material/Link";
// Mui Icons
import GitHubIcon from "@mui/icons-material/GitHub";
import AppDemoModal from "./AppDemoModal";
// Assets
import wellnessGif from "../../assets/wellness.gif";

//==============================================================================

export default function Assessments() {
  const [showElements, setShowElements] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function handleVisitToRepo() {
    window.open(
      "https://github.com/J-Tapio/athlete_monitoring",
      "_blank",
      "noopener"
    );
  }

  useEffect(() => {
    setShowElements(true);
  }, []);

  return (
    <>
      <Grid item xs={12} md={6}>
        <Stack spacing={2}>
          <Fade in={showElements} timeout={2000}>
            <Typography id="p" variant="h4">
              Assessment questionnaires
            </Typography>
          </Fade>
          <Fade in={showElements} timeout={2500}>
            <Typography component="p" sx={{ lineHeight: "1.6rem" }}>
              Working on transferring questionnaires within Sports and
              Physiotherapy to usable <b>React</b> components. <br />
              <br />
              Currently project contains{" "}
              <b>Subjective wellness questionnaire</b> which is used within
              Sports for athlete assessment.
              <br />
              <br />
              Also, I included body chart map where one could click on certain
              body areas of concern / pain.
              <br />
              Next step would be to include numerical pain rating scale for
              clicked body area of concern in order to have more meaningful
              information about body areas of concern.
              <br />
              <br />
              Interested also to learn about{" "}
              <Link
                href="https://github.com/BuilderIO/mitosis"
                rel="noreferrer"
                target="_blank"
                underline="none"
                sx={{
                  fontWeight: 500,
                  color: "inherit",
                  transition: "color .7s",
                  "&:hover": {
                    color: "#439d34",
                  },
                }}
              >
                Mitosis
              </Link>{" "}later where one could write component once and compile for different framework. This could be be very useful to have components available for different frameworks.
            </Typography>
          </Fade>
          <Fade in={showElements} timeout={3000}>
            <Typography variant="h6">Link to repository</Typography>
          </Fade>
          <Fade in={showElements} timeout={3000}>
            <GitHubIcon
              onClick={handleVisitToRepo}
              sx={{
                fontSize: "2rem",
                cursor: "pointer",
                "&:hover": { color: "#439d34" },
              }}
            />
          </Fade>
        </Stack>
      </Grid>
      <Grid item xs={12} md={6}>
        <AppDemoModal
          open={open}
          handleClose={handleClose}
          imageSrc={wellnessGif}
        />
        <Grow in={true} timeout={3000}>
          <Box
            component="img"
            onClick={handleOpen}
            sx={{
              borderRadius: "10px",
              objectFit: "cover",
              width: "100%",
              cursor: { md: "pointer" },
            }}
            src={wellnessGif}
            loading="lazy"
          />
        </Grow>
      </Grid>
    </>
  );
}
