import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
// Components
import MyHomePage from "./projectCards/MyHomePage";
import HackerNews from "./projectCards/Hackernews";
import MarkdownUpload from "./projectCards/MarkdownUpload";
import MarkdownArchives from "./projectCards/MarkdownArchives";
import TheVerge from "./projectCards/TheVerge";
import MeaningfulBookmarks from "./projectCards/MeaningfulBookmarks";
import TeamCompanion from "./projectCards/TeamCompanion";
import NewVerge from "./projectCards/NewVerge";
import Dynamometer from "./projectCards/Dynamometer";
import Assessments from "./projectCards/Assessments";
//============================================================================//

function SideProjects() {
  return(
    <Grid item xs={12}>
      <Typography variant="h2">Currently working on</Typography>
    </Grid>
  );
}

//==============================================================================

export default function PortfolioContainer() {
  return (
    <Container sx={{marginTop: "5rem", maxWidth: "1300px"}}>
      <Grid container columnSpacing={3} rowSpacing={{xs: 5, md:15}} sx={{ bgcolor: "background.default", color: "text.primary"}}>
        <MyHomePage />
        <HackerNews />
        <MarkdownUpload />
        <MarkdownArchives />
        <TheVerge />
        <MeaningfulBookmarks />
        <NewVerge />
        <TeamCompanion />
        <SideProjects />
        <Dynamometer />
        <Assessments />
      </Grid>
    </Container>
  );
}
