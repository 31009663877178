import { useState, useEffect } from "react";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Fade from "@mui/material/Fade";
import Grow from "@mui/material/Grow";
// Mui Icons
import GitHubIcon from "@mui/icons-material/GitHub";
// Components
import AppDemoModal from "./AppDemoModal";
// Assets
import hackerNewsGif from "../../assets/go-hackernews-app.gif";

//==============================================================================

export default function HackerNews() {
  const [showElements, setShowElements] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function handleVisitToRepo() {
    window.open(
      "https://github.com/J-Tapio/go-colly-fyne-hackernews",
      "_blank",
      "noopener"
    );
  }

  useEffect(() => {
    setShowElements(true);
  }, []);
  // Left-side
  // App title
  // Information
  // Techstack
  // Link to repos

  // Right side
  // App gif
  return (
    <>
      <Grid item xs={12} md={6}>
        <Stack spacing={2}>
          <Fade in={showElements} timeout={2000}>
            <Typography variant="h4">
              <Link
                href="https://github.com/J-Tapio/go-colly-fyne-hackernews"
                rel="noreferrer"
                target="_blank"
                underline="none"
                sx={{
                  color: "inherit",
                  transition: "color .7s",
                  "&:hover": {
                    color: "#439d34",
                  },
                }}
              >
                The Hackernews GUI
              </Link>
            </Typography>
          </Fade>
          <Fade in={showElements} timeout={2500}>
            <Typography component="p" sx={{ lineHeight: "1.6rem" }}>
              This is a project where I wanted to learn use of{" "}
              <b>
                <Link
                  href="https://go.dev"
                  rel="noreferrer"
                  target="_blank"
                  underline="none"
                  sx={{
                    color: "inherit",
                    transition: "color .7s",
                    "&:hover": {
                      color: "#439d34",
                    },
                  }}
                >
                  Go
                </Link>
              </b>{" "}
              frameworks,{" "}
              <b>
                <Link
                  href="https://fyne.io"
                  rel="noreferrer"
                  target="_blank"
                  underline="none"
                  sx={{
                    color: "inherit",
                    transition: "color .7s",
                    "&:hover": {
                      color: "#439d34",
                    },
                  }}
                >
                  Fyne
                </Link>
              </b>{" "}
              and{" "}
              <b>
                {" "}
                <Link
                  href="http://go-colly.org"
                  rel="noreferrer"
                  target="_blank"
                  underline="none"
                  sx={{
                    color: "inherit",
                    transition: "color .7s",
                    "&:hover": {
                      color: "#439d34",
                    },
                  }}
                >
                  Colly
                </Link>
              </b>
              .
              <br />
              GUI is built with{" "}
              <b>
                <Link
                  href="https://fyne.io"
                  rel="noreferrer"
                  target="_blank"
                  underline="none"
                  sx={{
                    color: "inherit",
                    transition: "color .7s",
                    "&:hover": {
                      color: "#439d34",
                    },
                  }}
                >
                  Fyne
                </Link>
              </b>
              .{" "}
              <b>
                <Link
                  href="http://go-colly.org"
                  rel="noreferrer"
                  target="_blank"
                  underline="none"
                  sx={{
                    color: "inherit",
                    transition: "color .7s",
                    "&:hover": {
                      color: "#439d34",
                    },
                  }}
                >
                  Colly
                </Link>
              </b>{" "}
              handles website scraping from{" "}
              <b>
                <Link
                  href="https://www.thehackernews.com"
                  rel="noreferrer"
                  target="_blank"
                  underline="none"
                  sx={{
                    color: "inherit",
                    transition: "color .7s",
                    "&:hover": {
                      color: "#439d34",
                    },
                  }}
                >
                  The Hackernews
                </Link>
              </b>{" "}
              website. Scraping is done every 30 minutes for newest article
              titles with links.
              <br />
              <br />
              This was my very first app written in{" "}
              <b>
                <Link
                  href="https://go.dev"
                  rel="noreferrer"
                  target="_blank"
                  underline="none"
                  sx={{
                    color: "inherit",
                    transition: "color .7s",
                    "&:hover": {
                      color: "#439d34",
                    },
                  }}
                >
                  Go
                </Link>
              </b>{" "}
              after I started learning it by the end of April 2022.
            </Typography>
          </Fade>
          <Fade in={showElements} timeout={3000}>
            <Typography variant="h6">Link to repository</Typography>
          </Fade>
          <Fade in={showElements} timeout={3000}>
            <GitHubIcon
              onClick={handleVisitToRepo}
              sx={{
                fontSize: "2rem",
                cursor: "pointer",
                color: "inherit",
                "&:hover": { color: "#439d34" },
              }}
            />
          </Fade>
        </Stack>
      </Grid>
      <Grid item xs={12} md={6}>
        <AppDemoModal
          open={open}
          handleClose={handleClose}
          imageSrc={hackerNewsGif}
        />
        <Grow in={showElements} timeout={3000}>
          <Box
            component="img"
            onClick={handleOpen}
            sx={{
              borderRadius: "10px",
              objectFit: "cover",
              width: "100%",
              cursor: { md: "pointer" },
            }}
            src={hackerNewsGif}
          />
        </Grow>
      </Grid>
    </>
  );
}
