import { useEffect, useState } from "react";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Fade from "@mui/material/Fade";

//==============================================================================

export default function Header() {
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    setFadeIn(true);
  }, []);

  return (
    <Container sx={{ maxWidth: "1300px" }}>
      <Fade in={fadeIn} timeout={2000}>
        <Typography variant="h1">Portfolio</Typography>
      </Fade>
      <Fade in={fadeIn} timeout={2500}>
        <Typography
          component="p"
          sx={{
            fontWeight: "500",
            paddingLeft: "0.3rem", //Fix tiny alignment issue
            fontSize: {
              xs: "1rem",
              sm: "1.3rem",
              md: "1.5rem",
              lg: "1.6rem",
              xl: "1.7rem",
            },
          }}
        >
          by{" "}
          <Link
            href={"https://www.juha-tap.io"}
            underline="none"
            rel="noreferrer"
            target="_blank"
            sx={{
              color: "#439d34",
              transition: "color .7s",
              "&:hover": {
                color: "#264520",
              },
            }}
          >
            Juha-Tapio Turpeinen
          </Link>
          .
        </Typography>
      </Fade>
    </Container>
  );
}