import { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Fade from "@mui/material/Fade";
import Grow from "@mui/material/Grow";
// Components
import AppDemoModal from "./AppDemoModal";
// Mui Icons
import GitHubIcon from "@mui/icons-material/GitHub";
// Assets
import teamCompanionGif from "../../assets/teamCompanion.gif";

//==============================================================================

export default function TeamCompanion() {
  const [showElements, setShowElements] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function handleVisitToBackendRepo() {
    window.open(
      "https://github.com/J-Tapio/teamCompanion",
      "_blank",
      "noopener"
    );
  }

  function handleVisitToFrontendRepo() {
    window.open(
      "https://github.com/J-Tapio/teamCompanion-fe",
      "_blank",
      "noopener"
    );
  }

  useEffect(() => {
    setShowElements(true);
  }, []);

  return (
    <>
      <Grid item xs={12} md={6}>
        <Stack spacing={2}>
          <Fade in={showElements} timeout={2000}>
            <Typography
              variant="h4"
              sx={{ cursor: "pointer" }}
              onClick={() => {
                setOpen(true);
              }}
            >
              teamCompanion
            </Typography>
          </Fade>
          <Fade in={showElements} timeout={2500}>
            <Typography component="p" sx={{ lineHeight: "1.8rem" }}>
              My largest project so far. Overall, I
              hope to finish this project someday and possibly provide a useful
              tool for divisional teams where people still rely on eg. Excel sheets
              and other applications to manage the team events, training, etc. collectively.
              <br />
              <br />
              For this project, I wanted to try out some new backend framework
              since I already had experience with <b>Koa.js</b> and{" "}
              <b>Express.js</b>. I decided to go with <b>Fastify</b>.
              <br />
              <br />
              <b>Postgres</b> database is handled through ORM,{" "}
              <b>Objection.js</b>. I decided to learn Objection.js since it is
              built on top of <b>Knex.js</b>, SQL query builder. I had already
              experience with Knex.js, but not so much about ORMs other than{" "}
              <b>Sequelize</b> through bootcamp for few days since main focus of
              bootcamp was with <b>MongoDB</b>.
              <br />
              <br />
              Don't get me wrong, I like MongoDB but for some reason I preferred
              to go with relational database management system.
              <br />
              <br />
              For project backend, I also wanted to write tests with{" "}
              <b>Mocha.js</b> and used assertion library <b>Chai.js</b> since I
              knew that writing tests would allow me to clarify and
              think-through the business logic besides planning out database
              tables.
              <br />
              <br />
              The frontend is built with <b>React.js</b> and <b>MaterialUI</b>.
              <br />
              <br />
              If you wish, contact me and I will share the link to the site so you
              can test the currently deployed demo-app.
            </Typography>
          </Fade>
          <Fade in={showElements} timeout={3000}>
            <Typography variant="h6">Links to repositories</Typography>
          </Fade>
          <Fade in={showElements} timeout={3000}>
            <Box
              display="flex"
              alignItems="center"
              onClick={handleVisitToFrontendRepo}
              sx={{
                fontSize: "2rem",
                cursor: "pointer",
                transition: "color 1s",
                "&:hover": { color: "#439d34" },
              }}
            >
              <GitHubIcon
                onClick={handleVisitToFrontendRepo}
                sx={{
                  fontSize: "2rem",
                }}
              />
              <Typography component="p" sx={{ ml: 2, fontWeight: 500 }}>
                Frontend
              </Typography>
            </Box>
          </Fade>
          <Fade in={showElements} timeout={3000}>
            <Box
              display="flex"
              alignItems="center"
              onClick={handleVisitToBackendRepo}
              sx={{
                fontSize: "2rem",
                cursor: "pointer",
                transition: "color 1s",
                "&:hover": { color: "#439d34" },
              }}
            >
              <GitHubIcon
                onClick={handleVisitToBackendRepo}
                sx={{
                  fontSize: "2rem",
                }}
              />
              <Typography component="p" sx={{ ml: 2, fontWeight: 500 }}>
                Backend
              </Typography>
            </Box>
          </Fade>
        </Stack>
      </Grid>
      <Grid item xs={12} md={6}>
        <AppDemoModal
          open={open}
          handleClose={handleClose}
          imageSrc={teamCompanionGif}
        />
        <Grow in={showElements} timeout={3000}>
          <Box
            onClick={handleOpen}
            component="img"
            sx={{
              borderRadius: "10px",
              objectFit: "cover",
              width: "100%",
              cursor: "pointer",
            }}
            src={teamCompanionGif}
          />
        </Grow>
      </Grid>
    </>
  );
}
