import { useState, useEffect } from "react";
// MaterialUI
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Fade from "@mui/material/Fade";
import Grow from "@mui/material/Grow";
// Mui Icons
import GitHubIcon from "@mui/icons-material/GitHub";
import AppDemoModal from "./AppDemoModal";
// Assets
import newVergeGif from "../../assets/vergeport.gif";

//==============================================================================

export default function NewVerge() {
  const [showElements, setShowElements] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function handleVisitToRepo() {
    window.open("https://github.com/J-Tapio/tverge-news", "_blank", "noopener");
  }

  useEffect(() => {
    setShowElements(true);
  }, []);

  return (
    <>
      <Grid item xs={12} md={6}>
        <Stack spacing={2}>
          <Fade in={showElements} timeout={2000}>
            <Typography id="meaningful-bookmarks" variant="h4">
              <Link
                href="https://github.com/J-Tapio/tverge-news"
                rel="noreferrer"
                target="_blank"
                underline="none"
                sx={{
                  color: "inherit",
                  transition: "color .7s",
                  "&:hover": {
                    color: "#439d34",
                  },
                }}
              >
                News Feed
              </Link>
            </Typography>
          </Fade>
          <Fade in={showElements} timeout={2500}>
            <Typography component="p" sx={{ lineHeight: "1.6rem" }}>
              Improvise, adapt, overcome.
              <br />
              This is how I felt when The Verge published their new website. At
              first, I thought that the site looked visually great, but it took
              me under a minute to realise there is just too much going on for
              the eyes to keep reading what articles are available.
              <br />
              <br />I decided to use{" "}
              <Link
                href="https://wails.io"
                rel="noreferrer"
                target="_blank"
                underline="none"
                sx={{
                  fontWeight: 500,
                  color: "inherit",
                  transition: "color .7s",
                  "&:hover": {
                    color: "#439d34",
                  },
                }}
              >
                Wails
              </Link>{" "}
              again to build my news-feed app.
              <br />
              Scraping is done with a Go Colly scraper. Visually, I tried to
              mimic the styling from the original site when it comes to article
              cards.
              <br />
              The Frontend is built with <b>React.js</b> and for styling,{" "}
              <b>TailwindCSS</b> is used.
              <br />
            </Typography>
          </Fade>
          <Fade in={showElements} timeout={3000}>
            <Typography variant="h6">Link to repository</Typography>
          </Fade>
          <Fade in={showElements} timeout={3000}>
            <GitHubIcon
              onClick={handleVisitToRepo}
              sx={{
                fontSize: "2rem",
                cursor: "pointer",
                "&:hover": { color: "#439d34" },
              }}
            />
          </Fade>
        </Stack>
      </Grid>
      <Grid item xs={12} md={6}>
        <AppDemoModal
          open={open}
          handleClose={handleClose}
          imageSrc={newVergeGif}
        />
        <Grow in={showElements} timeout={3000}>
          <Box
            component="img"
            onClick={handleOpen}
            sx={{
              borderRadius: "10px",
              objectFit: "cover",
              width: "100%",
              cursor: { md: "pointer" },
            }}
            src={newVergeGif}
            loading="lazy"
          />
        </Grow>
      </Grid>
    </>
  );
}
