import { useState, useEffect } from "react";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Fade from "@mui/material/Fade";
import Grow from "@mui/material/Grow";
// Mui Icons
import GitHubIcon from "@mui/icons-material/GitHub";
// Components
import AppDemoModal from "./AppDemoModal";
// Assets
import theVergeImg from "../../assets/theverge-clone.webp";

//==============================================================================

export default function TheVerge() {
  const [showElements, setShowElements] = useState(false);
  const [open, setOpen] = useState(false);
  //const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function handleVisitToBackendRepo() {
    window.open(
      "https://github.com/J-Tapio/go-tverge-be",
      "_blank",
      "noopener"
    );
  }

  function handleVisitToFrontendRepo() {
    window.open(
      "https://github.com/J-Tapio/tverge-react",
      "_blank",
      "noopener"
    );
  }

  function handleVisitToVerge() {
    window.open(
      "https://tverge.juha-tap.io",
      "_blank",
      "noopener"
    )
  }

  useEffect(() => {
    setShowElements(true);
  }, []);

  return (
    <>
      <Grid item xs={12} md={6}>
        <Stack spacing={2}>
          <Fade in={showElements} timeout={2000}>
            <Typography variant="h4">
              <Link
                href="https://tverge.juha-tap.io"
                rel="noreferrer"
                target="_blank"
                underline="none"
                sx={{
                  color: "inherit",
                  transition: "color .7s",
                  "&:hover": {
                    color: "#439d34",
                  },
                }}
              >
                The Verge clone
              </Link>
            </Typography>
          </Fade>
          <Fade in={showElements} timeout={2500}>
            <Typography component="p" sx={{ lineHeight: "1.6rem" }}>
              Project where I wanted to learn{" "}
              <b>
                <Link
                  href="https://mui.com"
                  rel="noreferrer"
                  target="_blank"
                  underline="none"
                  sx={{
                    color: "inherit",
                    transition: "color .7s",
                    "&:hover": {
                      color: "#439d34",
                    },
                  }}
                >
                  MaterialUI
                </Link>
              </b>
              . <br />
              As an exercise I tried to clone{" "}
              <b>
                <Link
                  href="https://www.theverge.com"
                  rel="noreferrer"
                  target="_blank"
                  underline="none"
                  sx={{
                    color: "inherit",
                    transition: "color .7s",
                    "&:hover": {
                      color: "#439d34",
                    },
                  }}
                >
                  The Verge
                </Link>
              </b>{" "}
              main page. <br />
              Since I could find only one API that gives me 10-15 new articles,
              I created a backend with a scraper to scrape the front page for
              the latest news article links, titles, and images.
              <br />
              <br />
              The backend for this project is written in{" "}
              <b>
                <Link
                  href="https://go.dev"
                  rel="noreferrer"
                  target="_blank"
                  underline="none"
                  sx={{
                    color: "inherit",
                    transition: "color .7s",
                    "&:hover": {
                      color: "#439d34",
                    },
                  }}
                >
                  Go
                </Link>
              </b>
              . <br />
              <br />
              Backend handles web scraping every hour and serving of latest news
              articles, including title, link and image.
              <br />
              <br />
              <b>
                Unfortunately on 13th of September 2022
                {"  "}
                <Link
                  href="https://www.theverge.com"
                  rel="noreferrer"
                  target="_blank"
                  underline="none"
                  sx={{
                    fontSize: "1.2rem",
                    color: "inherit",
                    transition: "color .7s",
                    "&:hover": {
                      color: "#439d34",
                    },
                  }}
                >
                  The Verge
                </Link>{" "}
                did overhaul to their site and backend / scraper does not function
                anymore. You can still visit the clone pages but they only
                present backup images.
              </b>
            </Typography>
          </Fade>
          <Fade in={showElements} timeout={3000}>
            <Typography variant="h6">Links to repositories</Typography>
          </Fade>
          <Fade in={showElements} timeout={3000}>
            <Box
              display="flex"
              alignItems="center"
              onClick={handleVisitToFrontendRepo}
              sx={{
                fontSize: "2rem",
                cursor: "pointer",
                transition: "color 1s",
                "&:hover": { color: "#439d34" },
              }}
            >
              <GitHubIcon
                onClick={handleVisitToFrontendRepo}
                sx={{
                  fontSize: "2rem",
                }}
              />
              <Typography component="p" sx={{ ml: 2, fontWeight: 500 }}>
                Frontend
              </Typography>
            </Box>
          </Fade>
          <Fade in={showElements} timeout={3000}>
            <Box
              display="flex"
              alignItems="center"
              onClick={handleVisitToBackendRepo}
              sx={{
                fontSize: "2rem",
                cursor: "pointer",
                transition: "color 1s",
                "&:hover": { color: "#439d34" },
              }}
            >
              <GitHubIcon
                onClick={handleVisitToBackendRepo}
                sx={{
                  fontSize: "2rem",
                }}
              />
              <Typography component="p" sx={{ ml: 2, fontWeight: 500 }}>
                Backend
              </Typography>
            </Box>
          </Fade>
        </Stack>
      </Grid>
      <Grid item xs={12} md={6}>
        <AppDemoModal
          open={open}
          handleClose={handleClose}
          imageSrc={theVergeImg}
        />
        <Grow in={showElements} timeout={3000}>
          <Box
            component="img"
            onClick={() => {
              //handleOpen();
              handleVisitToVerge();
            }}
            sx={{
              borderRadius: "10px",
              objectFit: "cover",
              width: "100%",
              cursor: { md: "pointer" },
            }}
            src={theVergeImg}
          />
        </Grow>
      </Grid>
    </>
  );
}
