import { useState, useEffect } from "react";
// MaterialUI
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Fade from "@mui/material/Fade";
import Grow from "@mui/material/Grow";
// Mui Icons
import GitHubIcon from "@mui/icons-material/GitHub";
import AppDemoModal from "./AppDemoModal";
// Assets
import dynoGif from "../../assets/hh_dyno.gif";

//==============================================================================

export default function Dynamometer() {
  const [showElements, setShowElements] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function handleVisitToRepo() {
    window.open(
      "https://github.com/J-Tapio/hh-dynamometer-app",
      "_blank",
      "noopener"
    );
  }

  useEffect(() => {
    setShowElements(true);
  }, []);

  return (
    <>
      <Grid item xs={12} md={6}>
        <Stack spacing={2}>
          <Fade in={showElements} timeout={2000}>
            <Typography id="p" variant="h4">
              Digital handheld dynamometer
            </Typography>
          </Fade>
          <Fade in={showElements} timeout={2500}>
            <Typography component="p" sx={{ lineHeight: "1.6rem" }}>
              Learning <b>Ionic framework</b> to create a mobile application for
              my DIY project, a handheld digital dynamometer to assess force
              output. Handheld dynamometer communicates via Bluetooth with
              mobile application.
              <br />
              <br />
              Handheld dynamometer is suitable for use within Physiotherapy and
              Sports. By the end of October 2022 I got interested in <b>Internet of
              Things (IoT)</b> as I was curious about <b>Arduino</b> and other
              microcontrollers.
              <br />
              <br />
              Ultimately, I would want to create a force platform to record and
              analyze force output from jump assessments. Hence, this project is
              a good starting point to move forward with the more challenging force
              platform next.
              <br />
              <br />
              This project has helped me to learn <b>C++</b> programming
              languate also as it is needed in order to program
              microcontrollers. Other option would've been to learn{" "}
              <b>Python</b> along with use of <b>MicroPython</b> but since I had
              already some experience with <b>Golang</b> I felt that `It can't
              be too difficult, right?!`. Oh well, to some extent I was right.
            </Typography>
          </Fade>
          <Fade in={showElements} timeout={3000}>
            <Typography variant="h6">Link to repository</Typography>
          </Fade>
          <Fade in={showElements} timeout={3000}>
            <GitHubIcon
              onClick={handleVisitToRepo}
              sx={{
                fontSize: "2rem",
                cursor: "pointer",
                "&:hover": { color: "#439d34" },
              }}
            />
          </Fade>
        </Stack>
      </Grid>
      <Grid item xs={12} md={6}>
        <AppDemoModal
          open={open}
          handleClose={handleClose}
          imageSrc={dynoGif}
        />
        <Grow in={true} timeout={3000}>
          <Box
            component="img"
            onClick={handleOpen}
            sx={{
              borderRadius: "10px",
              objectFit: "cover",
              width: "100%",
              cursor: { md: "pointer" },
            }}
            src={dynoGif}
            loading="lazy"
          />
        </Grow>
      </Grid>
    </>
  );
}
