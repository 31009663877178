import {useState, useEffect} from "react";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Fade from "@mui/material/Fade";
import Grow from "@mui/material/Grow";
// Components
import AppDemoModal from "./AppDemoModal";
// Assets
import myPageGif from "../../assets/homepage.gif";

//==============================================================================

export default function MyHomePage() {
  const [showElements, setShowElements] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    setShowElements(true);
  },[]);

  return (
    <>
      <Grid item xs={12} md={6}>
        <Stack spacing={2}>
          <Fade in={showElements} timeout={2000}>
            <Typography variant="h4">
              <Link
                href="https://juha-tap.io"
                rel="noreferrer"
                target="_blank"
                underline="none"
                sx={{
                  color: "inherit",
                  transition: "color .7s",
                  "&:hover": {
                    color: "#439d34",
                  },
                }}
              >
                juha-tap.io
              </Link>
            </Typography>
          </Fade>
          <Fade in={showElements} timeout={2500}>
            <Typography component="p" sx={{ lineHeight: "1.8rem" }}>
              My own webpages were created well before I attended coding
              bootcamp or learned about different CSS libraries or JS frameworks
              etc. <br />
              Plain use of HTML, CSS, and Javascript within this page. <br />{" "}
              Inspiration for making a command-line style page came after
              finding a typewriter effect. <br />
              <br />
              Maybe someday I will add a cool start effect to my page, which
              reminds me of turning on a computer screen in the early 90's. That
              bright line centered on the screen when you turned the screen
              on... How cool was that when I was a child!? <br />
              Seriously though, I think I managed to break one of our computer
              screens by turning the screen on/off all the time to see the
              'effect'.<br /><br />
              <b>Page was rebuilt with React in the beginning of year 2023.</b>
            </Typography>
          </Fade>
        </Stack>
      </Grid>
      <Grid item xs={12} md={6}>
        <AppDemoModal
          open={open}
          handleClose={handleClose}
          imageSrc={myPageGif}
        />
        <Grow in={showElements} timeout={3000}>
          <Box
            onClick={handleOpen}
            component="img"
            sx={{
              borderRadius: "10px",
              objectFit: "cover",
              width: "100%",
              cursor: "pointer",
            }}
            src={myPageGif}
          />
        </Grow>
      </Grid>
    </>
  );
}