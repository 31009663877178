import {useContext} from "react";
import { ColorModeContext } from "../App";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container"
import IconButton from "@mui/material/IconButton";
// Mui Icons
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";

//==============================================================================

export default function ColorModeToggle() {
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);

  return (
    <Container sx={{ maxWidth: "1300px" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          bgcolor: "background.default",
          color: "text.primary",
          paddingTop: "1rem",
          paddingRight: "1rem",
          margin: "0 auto",
        }}
      >
        {theme.palette.mode} mode
        <IconButton
          sx={{ ml: 1 }}
          onClick={colorMode.toggleColorMode}
          color="inherit"
        >
          {theme.palette.mode === "dark" ? (
            <Brightness7Icon />
          ) : (
            <Brightness4Icon />
          )}
        </IconButton>
      </Box>
    </Container>
  );
}
