import { useState, useEffect } from "react";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Fade from "@mui/material/Fade";
import Grow from "@mui/material/Grow";
// Mui Icons
import GitHubIcon from "@mui/icons-material/GitHub";
// Assets
import markdownArchivesImg from "../../assets/go-markdown-archives.webp";

//==============================================================================

export default function MarkdownArchives() {
  const [showElements, setShowElements] = useState(false);

  function handleVisitToRepo() {
    window.open(
      "https://github.com/J-Tapio/go-notes-app",
      "_blank",
      "noopener"
    );
  }

  function handleVisitToSite() {
    window.open("https://notes.juha-tap.io", "_blank", "noopener");
  }

  useEffect(() => {
    setShowElements(true);
  }, []);

  return (
    <>
      <Grid item xs={12} md={6}>
        <Stack spacing={2}>
          <Fade in={showElements} timeout={2000}>
            <Typography id="markdown-archives" variant="h4">
              <Link
                href="https://notes.juha-tap.io"
                rel="noreferrer"
                target="_blank"
                underline="none"
                sx={{
                  color: "inherit",
                  transition: "color .7s",
                  "&:hover": {
                    color: "#439d34",
                  },
                }}
              >
                Markdown Archives
              </Link>
            </Typography>
          </Fade>
          <Fade in={showElements} timeout={2500}>
            <Typography component="p" sx={{ lineHeight: "1.6rem" }}>
              Project where I wanted to create my 'Markdown archives'
              -pages with the help of{" "}
              <b>
                <Link
                  href="https://www.gomponents.com"
                  rel="noreferrer"
                  target="_blank"
                  underline="none"
                  sx={{
                    color: "inherit",
                    transition: "color .7s",
                    "&:hover": {
                      color: "#439d34",
                    },
                  }}
                >
                  Gomponents
                </Link>
              </b>
              , view components written in pure{" "}
              <b>
                <Link
                  href="https://go.dev"
                  rel="noreferrer"
                  target="_blank"
                  underline="none"
                  sx={{
                    color: "inherit",
                    transition: "color .7s",
                    "&:hover": {
                      color: "#439d34",
                    },
                  }}
                >
                  Go
                </Link>
              </b>
              .<br />
              <br />
              Instead of providing single-page with notes, I went a little bit
              further to learn on how to use{" "}
              <b>
                <Link
                  href="https://github.com/gorilla/mux"
                  rel="noreferrer"
                  target="_blank"
                  underline="none"
                  sx={{
                    color: "inherit",
                    transition: "color .7s",
                    "&:hover": {
                      color: "#439d34",
                    },
                  }}
                >
                  Go Mux
                </Link>
              </b>{" "}
              router by serving several pages. <br />
              <br />
              This project also helped me to learn{" "}
              <b>
                <Link
                  href="https://tailwindcss.com"
                  rel="noreferrer"
                  target="_blank"
                  underline="none"
                  sx={{
                    color: "inherit",
                    transition: "color .7s",
                    "&:hover": {
                      color: "#439d34",
                    },
                  }}
                >
                  Tailwind CSS
                </Link>
              </b>{" "}
              quite a bit. <br />
              <br />
              In overall, these pages hopefully will be helpful whenever I need
              to refresh something from my notes.
            </Typography>
          </Fade>
          <Fade in={showElements} timeout={3000}>
            <Typography variant="h6">Link to repository</Typography>
          </Fade>
          <Fade in={showElements} timeout={3000}>
            <GitHubIcon
              onClick={handleVisitToRepo}
              sx={{
                fontSize: "2rem",
                cursor: "pointer",
                "&:hover": { color: "#439d34" },
              }}
            />
          </Fade>
        </Stack>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grow in={showElements} timeout={3000}>
          <Box
            component="img"
            onClick={handleVisitToSite}
            sx={{
              borderRadius: "10px",
              objectFit: "cover",
              width: "100%",
              cursor: { md: "pointer" },
            }}
            src={markdownArchivesImg}
          />
        </Grow>
      </Grid>
    </>
  );
}
