import { useState, useEffect } from "react";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Fade from "@mui/material/Fade";
import Grow from "@mui/material/Grow";
// Mui Icons
import GitHubIcon from "@mui/icons-material/GitHub";
// Components
import AppDemoModal from "./AppDemoModal";
// Assets
import markdownUploadGif from "../../assets/go-markdown-upload.gif";

//==============================================================================

export default function MarkdownUpload() {
  const [showElements, setShowElements] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function handleVisitToRepo() {
    window.open(
      "https://github.com/J-Tapio/go-fyne-markdown-upload",
      "_blank",
      "noopener"
    );
  }

  useEffect(() => {
    setShowElements(true);
  }, []);

  return (
    <>
      <Grid item xs={12} md={6}>
        <Stack spacing={2}>
          <Fade in={showElements} timeout={2000}>
            <Typography variant="h4">
              {" "}
              <Link
                href="https://github.com/J-Tapio/go-fyne-markdown-upload"
                rel="noreferrer"
                target="_blank"
                underline="none"
                sx={{
                  color: "inherit",
                  transition: "color .7s",
                  "&:hover": {
                    color: "#439d34",
                  },
                }}
              >
                Markdown upload GUI
              </Link>
            </Typography>
          </Fade>
          <Fade in={showElements} timeout={2500}>
            <Typography component="p" sx={{ lineHeight: "1.6rem" }}>
              Project where I created GUI app with{" "}
              <b>
                <Link
                  href="https://fyne.io"
                  rel="noreferrer"
                  target="_blank"
                  underline="none"
                  sx={{
                    color: "inherit",
                    transition: "color .7s",
                    "&:hover": {
                      color: "#439d34",
                    },
                  }}
                >
                  Fyne
                </Link>
              </b>{" "}
              framework to make it easy for me to upload my{" "}
              <b>
                <Link
                  href="https://en.wikipedia.org/wiki/Markdown"
                  rel="noreferrer"
                  target="_blank"
                  underline="none"
                  sx={{
                    color: "inherit",
                    transition: "color .7s",
                    "&:hover": {
                      color: "#439d34",
                    },
                  }}
                >
                  Markdown
                </Link>
              </b>{" "}
              notes to my{" "}
              <b>
                {" "}
                <Link
                  href="https://www.mongodb.com"
                  rel="noreferrer"
                  target="_blank"
                  underline="none"
                  sx={{
                    color: "inherit",
                    transition: "color .7s",
                    "&:hover": {
                      color: "#439d34",
                    },
                  }}
                >
                  MongoDB
                </Link>
              </b>{" "}
              collection. <br />
              <br />
              <b>
                <Link
                  href="https://www.mongodb.com"
                  rel="noreferrer"
                  target="_blank"
                  underline="none"
                  sx={{
                    color: "inherit",
                    transition: "color .7s",
                    "&:hover": {
                      color: "#439d34",
                    },
                  }}
                >
                  MongoDB
                </Link>
              </b>{" "}
              collection is used within my{" "}
              <b>
                <a
                  href="#markdown-archives"
                  style={{ textDecoration: "none", color: "#439d34" }}
                >
                  Markdown Archives
                </a>
              </b>{" "}
              app.
            </Typography>
          </Fade>
          <Fade in={showElements} timeout={3000}>
            <Typography variant="h6">Link to repository</Typography>
          </Fade>
          <Fade in={showElements} timeout={3000}>
            <GitHubIcon
              onClick={handleVisitToRepo}
              sx={{
                fontSize: "2rem",
                cursor: "pointer",
                transition: "color 1s",
                "&:hover": { color: "#439d34" },
              }}
            />
          </Fade>
        </Stack>
      </Grid>
      <Grid item xs={12} md={6}>
        <AppDemoModal
          open={open}
          handleClose={handleClose}
          imageSrc={markdownUploadGif}
        />
        <Grow in={showElements} timeout={3000}>
          <Box
            component="img"
            onClick={handleOpen}
            sx={{
              borderRadius: "10px",
              objectFit: "cover",
              width: "100%",
              cursor: { md: "pointer" },
            }}
            src={markdownUploadGif}
          />
        </Grow>
      </Grid>
    </>
  );
}
