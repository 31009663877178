import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";


const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "80%",
  maxHeight: "80%",
  border: "2px solid #000",
  boxShadow: 24,
};

type AppDemoModalProps = {
  open: boolean;
  handleClose: () => void;
  imageSrc: string;
}

export default function AppDemoModal({open, handleClose, imageSrc}:AppDemoModalProps) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-app-demo"
      aria-describedby="modal-app-demo"
    >
      <Box sx={style} component="img" src={imageSrc}>
      </Box>
    </Modal>
  );
}
